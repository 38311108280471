@if (groupName()) {
  <span class="chips-group-name">{{ groupName() }}</span>
}

@for (chip of chips() | slice: 0 : listLength(); track $index) {
  <span class="chip truncate">
    @if (chip.name) {
      <span class="chip__name truncate" [title]="chip.name">{{ chip.name }}</span>
    }
    <span class="chip__label truncate" [title]="chip.label">{{ chip.label }}</span>
  </span>
}

@if (chips().length > DISPLAY_NUMBER_OF_CHIPS) {
  @if (limitList()) {
    <span class="expand-button truncate">
      <span class="expand-button__label truncate" (click)="onClick()"
        >{{ chips().length - DISPLAY_NUMBER_OF_CHIPS }} weitere Filter anzeigen</span
      >
    </span>
  } @else {
    <span class="expand-button truncate">
      <span class="expand-button__label truncate" (click)="onClick()">Weniger anzeigen</span>
    </span>
  }
}
