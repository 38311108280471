import { SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  Signal,
  WritableSignal,
  computed,
  input,
  signal,
} from '@angular/core';

import { ChipProperties } from './chip-properties';

@Component({
  selector: 'mp-custom-chips',
  standalone: true,
  templateUrl: './custom-chips.component.html',
  styleUrl: './custom-chips.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SlicePipe],
})
export class CustomChipsComponent {
  readonly groupName: InputSignal<string> = input<string>('');
  readonly chips: InputSignal<ChipProperties[]> = input.required<ChipProperties[]>();

  readonly DISPLAY_NUMBER_OF_CHIPS = 3;

  protected readonly limitList: WritableSignal<boolean> = signal<boolean>(true);

  protected readonly listLength: Signal<number> = computed(() =>
    this.limitList() ? this.DISPLAY_NUMBER_OF_CHIPS : this.chips().length,
  );

  onClick(): void {
    this.limitList.set(!this.limitList());
  }
}
