<a
  class="link"
  [ngClass]="{ 'link--highlighted': isHighlighted, 'link--with-context-actions': contextMenuButtonVisible }"
  matRipple
  routerLinkActive="active"
  [routerLink]="[path]"
>
  <div class="content">
    <ng-content />
  </div>
</a>

<div class="context">
  @if (contextMenuButtonVisible) {
    <button mat-icon-button color="accent" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  }

  <mat-menu #menu="matMenu">
    <ng-content select="[mat-menu-item]" />
  </mat-menu>
</div>
