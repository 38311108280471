@if (icon && !avatar) {
  <div class="entity-item__icon">
    <mat-icon class="material-icons-outlined">{{ icon }}</mat-icon>
  </div>
}

@if (avatar) {
  <div class="entity-item__avatar">
    <mp-avatar
      [name]="avatar?.initials ?? label"
      [image]="avatar?.imagePath ? 'api/uploads/images/' + avatar.imagePath : undefined"
      size="tiny"
    />
  </div>
}

<div class="entity-item__label">
  <h5 class="entity-item__label-text truncate">{{ label }}</h5>
  <div class="entity-item__label-meta truncate">
    @if (chips?.length) {
      <mat-chip-list>
        @for (chip of chips; track $index) {
          <mat-chip>{{ chip.value }}</mat-chip>
        }
      </mat-chip-list>
    } @else {
      {{ meta }}
    }
  </div>
</div>

<div class="entity-item__actions">
  <ng-content select="button" />
  <ng-content select="additional-icon" />

  @if (editMode) {
    @if (!removed) {
      <button mat-icon-button (click)="removeClicked()">
        <mat-icon>clear</mat-icon>
      </button>
    } @else {
      <button mat-icon-button (click)="undoClicked()">
        <mat-icon>undo</mat-icon>
      </button>
    }
  }
</div>
